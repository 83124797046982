




















































import { useState } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'my-ean-info',
  components: {},
  props: {
    ean: {
      required: true,
      type: Object as () => Record<string, any>,
    },
  },

  setup(props, ctx) {
    const { i18n } = useState('my', ['i18n'])
    const { id, cpt } = ctx.root.$route.params

    return { i18n, id, cpt, apiDomain: process.env.VUE_APP_API_DOMAIN }
  },
})
