




























































































































































































import axios from 'axios'
import {
  defineComponent,
  onMounted,
  Ref,
  ref,
  watch,
} from '@vue/composition-api'
import { getApiUrl } from '@/inc/app.config'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import { toCamel } from '@/inc/utils/case'

import { trimzerosIndex } from '@/inc/utils'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import Toggle from '@/components/ui/Toggle.vue'
import EanInfo from '@/components/my/EanInfo.vue'

import TipPanel from '@/components/TipPanel.vue'
import { MyState } from '@/inc/types'
import gtm, { Event } from '@/composables/gtm'

export default defineComponent({
  name: 'my-meter-info',
  components: { FeedbackMessage, Toggle, EanInfo, TipPanel },
  props: {
    meterData: {
      required: true,
      type: Object as () => Record<string, any>,
    },
  },

  setup(props, ctx) {
    const { $logger } = ctx.root
    const { chrome } = useState(['chrome'])
    const { getEan } = useGetters('user', ['getEan'])
    const myState = {
      ...useState('my', ['i18n', 'smartData']),
    }
    const { fetchSmartData } = useActions('my', ['fetchSmartData'])

    const ean = getEan.value(ctx.root.$route.params.id)
    const activationRequest = ref(null)
    const feedbackMessage = ref('')
    const hasError = ref(false)
    const portP1Active = ref(true)
    const availableRequest = ['A', 'D']
    const { i18n } = useState('my', ['i18n']) as { i18n: Ref<MyState['i18n']> }
    const { showPanel, resetPanel } = useActions('sidePanel', [
      'showPanel',
      'resetPanel',
    ])

    // Methode pour afficher le panel
    const showTipPanel = () => {
      resetPanel()
      showPanel({
        component: {
          template: TipPanel,
        },
        content: {
          title: i18n.value.compteur.title,
          htmltext: i18n.value.compteur.htmltext,
        },
      })
    }

    const checkPort1 = () => {
      const availableAction = myState.smartData.value.resultat.charAt(0)
      if (
        myState.smartData.value.nbEncours.trim() === '1' ||
        myState.smartData.value.smartCom === 'NON' ||
        !availableRequest.includes(availableAction) ||
        myState.smartData.value.noMaj === 'X'
      ) {
        portP1Active.value = true
      } else {
        portP1Active.value = false
      }

      if (myState.smartData.value.nbEncours.trim() === '1') {
        feedbackMessage.value =
          myState.i18n.value.meterInfo.mymeter.successMessage
        hasError.value = false
      } else if (
        myState.smartData.value.noMaj === 'X' &&
        myState.smartData.value.nbEncours.trim() === '0'
      ) {
        feedbackMessage.value =
          myState.i18n.value.meterInfo.mymeter.errorMessage
        hasError.value = true
      } else {
        feedbackMessage.value = ''
        hasError.value = false
      }
    }

    const sendActivationRequest = () => {
      const availableAction = myState.smartData.value.resultat.charAt(0)
      if (availableRequest.includes(availableAction)) {
        try {
          portP1Active.value = true
          axios
            .post(
              `${getApiUrl()}/portP1/request?Action=${availableAction}&NumCpt=${
                myState.smartData.value.numCpt
              }`
            )
            .then(res => {
              fetchSmartData({
                ean: ean.ean,
                numCpt: myState.smartData.value.numCpt,
              })

              const event = {
                event: 'MyRESA_events',
                eventLabel: 'Activation__Port_P1',
              } as Event
              gtm.sendEvent(event)
            })
        } catch (error) {
          $logger.error(error)
          hasError.value = true
          feedbackMessage.value = error.res.data.resultat || 'Error'
        }
      }
    }

    const toHours = hour => hour?.replace(':', 'h')

    watch(myState.smartData, _ => {
      checkPort1()
    })

    watch(activationRequest, _ => {
      sendActivationRequest()
    })

    onMounted(() => {
      if (myState.smartData.value.resultat) {
        checkPort1()
      }
    })

    return {
      smartData: myState.smartData,
      activationRequest,
      ean,
      feedbackMessage,
      hasError,
      portP1Active,
      checkPort1,
      toCamel,
      toHours,
      trimzerosIndex,
      myI18n: myState.i18n,
      globalI18n: chrome.value.data.i18n,
      showTipPanel,
      i18n,
    }
  },
})
